import React from 'react';
import i18n from '../../config/i18n'
import axios from 'axios'
import 'react-notifications/lib/notifications.css';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import * as EmailValidator from 'email-validator'

class Form extends React.Component {

  state = {
    name: null,
    subject: null,
    email: null,
    message: null,
    sending: false,
    errors: {
      name: false,
      subject: false,
      email: false,
      message: false
    }
  }

  handleName = (e) => {
    this.setState({ name: e.target.value})
  }

  handleSubject = (e) => {
    this.setState({ subject: e.target.value})
  }

  handleEmail = (e) => {
    this.setState({ email: e.target.value})
  }

  handleMessage = (e) => {
    this.setState({ message: e.target.value})
  }

  send = (e) => {
    e.preventDefault()
    const formData = {
      'name': this.state.name ? this.state.name.trim() : '',
      'subject': this.state.subject ? this.state.subject.trim() : '',
      'email': this.state.email ? this.state.email.trim() : '',
      'message': this.state.message ? this.state.message.trim() : ''
    }

    const errors = {};

    if (!EmailValidator.validate(formData.email)) {
      errors.email = true;
    }

    if (formData.name.length === 0) {
      errors.name = true;
    }

    if (formData.subject.length === 0) {
      errors.subject = true;
    }

    if (formData.message.length === 0) {
      errors.message = true;
    }

    this.setState({ errors: {
      name: errors.name || false,
      subject: errors.subject || false,
      email: errors.email || false,
      message: errors.message || false,
    }})

    if (Object.keys(errors).length === 0) {
      this.setState({ sending: true }); 
      axios.post(`${process.env.BASE_URL}/.netlify/functions/contact`, 
      formData, 
      {
        headers: {
          'Content-Type': 'application/json',
        }
      }).then(res => {
        NotificationManager.success(i18n[this.props.locale].notifications[0], 'Contact');
        this.setState({ sending: false });
      })
    }
  }

  render() {
    const { locale } = this.props;
    return (
      <React.Fragment>
        <h4>{i18n[locale].labels[3]}</h4>
        <form onSubmit={this.send} name="contact" method="POST">
          <div className="form-group">
            <label htmlFor="name">{i18n[locale].labels[0]}</label>
            <input 
              className="form-control" 
              id="name" 
              type="text"
              onChange={this.handleName}
              style={{
                border: `${this.state.errors.name ? '1px solid #ff0000' : '1px solid #cccccc'}`
              }}
            />
          </div>
          <div className="form-group">
            <label htmlFor="name">{i18n[locale].labels[1]}</label>
            <input 
              className="form-control" 
              id="subject" 
              type="text"
              onChange={this.handleSubject}
              style={{
                border: `${this.state.errors.subject ? '1px solid #ff0000' : '1px solid #cccccc'}`
              }}
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">{i18n[locale].labels[2]}</label>
            <input
              type="email"
              className="form-control"
              id="email"
              placeholder="name@example.com"
              onChange={this.handleEmail}
              style={{
                border: `${this.state.errors.email ? '1px solid #ff0000' : '1px solid #cccccc'}`
              }}
            />
          </div>
          <div className="form-group">
            <label htmlFor="name">Message</label>
            <textarea 
              id="message" 
              onChange={this.handleMessage} 
              rows="5"
              style={{
                border: `${this.state.errors.message ? '1px solid #ff0000' : '1px solid #cccccc'}`
              }}
            ></textarea>
          </div>
          <div className="form-group">
            {this.state.sending ? (
              <button className="form-control btn btn-primary" type="submit" style={{ opacity: .5, cursor: 'default' }} disabled>
                {i18n[locale].notifications[1]}
              </button>
            ) : (
              <button className="form-control btn btn-primary" type="submit">
                {i18n[locale].buttons[2]}
              </button>
            )}
          </div>
        </form>
        <NotificationContainer />
      </React.Fragment>
    );
  }
}

export default Form;
