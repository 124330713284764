import React from 'react';
const Cta = ({ triggerText, buttonRef, showModal, button }) => {
  return (
    <React.Fragment>
      {
        button ? (
          <button
            className="btn btn-lg btn-danger center modal-button"
            ref={buttonRef}
            onClick={showModal}
          >
            {triggerText}
          </button>
        ) : (
          <button
            className="btn no-btn"
            ref={buttonRef}
            onClick={showModal}
          >
            {triggerText}
          </button>
        )
      }
    </React.Fragment>
  );
};
export default Cta;
