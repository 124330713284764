import React from "react";

export default ({ open, ...props }) => {
  return (
    <div className={open ? "burger-menu white open" : "burger-menu white"} {...props}>
      <div className="bar1" key="b1" />
      <div className="bar2" key="b2" />
      <div className="bar3" key="b3" />
    </div>
  )
};
