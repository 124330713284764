import React from 'react'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import i18n from '../../config/i18n'

const SEO = ({ title, desc, pathname, banner, locale, slug }) => {
  const { site } = useStaticQuery(query)
  const { defaultTitle, defaultDescription } = i18n[locale]
  
  const {
    buildTime,
    siteMetadata: { siteUrl, author }
  } = site
  
  const homeURL = `${siteUrl}`
  const ogImage = banner
  const seo = {
    title: title || defaultTitle,
    description: desc || defaultDescription,
    image: `${siteUrl}${ogImage}`,
    url: `${siteUrl}${pathname || ''}`,
  }
  
  const schemaOrgWebPage = {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    url: `${homeURL}${pathname}`,
    headline: title,
    inLanguage: locale,
    mainEntityOfPage: `${homeURL}${pathname}`,
    description: desc || defaultDescription,
    name: defaultTitle,
    author: {
      '@type': 'Person',
      name: author,
    },
    copyrightHolder: {
      '@type': 'Person',
      name: author,
    },
    copyrightYear: '2021',
    creator: {
      '@type': 'Person',
      name: author,
    },
    publisher: {
      '@type': 'Person',
      name: author,
    },
    datePublished: '2021-04-20T10:30:00+01:00',
    dateModified: buildTime,
    image: {
      '@type': 'ImageObject',
      url: ogImage,
    },
  }
  
  const schemaFAQ = {
    'en': {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": [{
        "@type": "Question",
        "name": "What is Virtual Magic ?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "<p>Virtual Magic is a term that describes a new form of magic that takes place and is experienced behind your computer screen, television or even your phone.</p><p>This term appeared at the start of the first confinement in 2020.</p><p>Virtual Magic is interactive. Imagine a magic show on TV or on Youtube but where in the magician can see and speak to every spectator and vice versa.</p>"
        }
      },{
        "@type": "Question",
        "name": "How does it work ?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "<p>Usually the event takes place as follows.</p><p>David's team will send you a unique link that will allow each person to access the show on a platform such as Zoom.</p><p>Each event is unique and David and his team will answer your questions in order to create a moment that suits you.</p>"
        }
      }, {
        "@type": "Question",
        "name": "Who is the show suitable for ?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "<p>Corporate events and private events.</p><p>It is a spoken show, geared more towards adults but also suitable for children.</p>"
        }
      }, {
        "@type": "Question",
        "name": "How much does it cost ?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "<p>Each event is unique.</p><p>The costs mainly vary with the number of guests and / or the Mystery Box (see Mystery box tab for more info).</p><p>Contact us and we will be happy to send you a quote when you tell us about your event.</p>"
        }
      }, {
        "@type": "Question",
        "name": "How long does a show last ?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "<p>On average, a Virtual Magic show lasts approximately 40 minutes.</p><p>For shorter or longer formats please get in touch with us.</p><p>David and his team will help to organise the show that suits you.</p>"
        }
      }]
    },
    'fr': {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": [{
        "@type": "Question",
        "name": "Qu’est ce que la Magie Virtuelle ?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "<p>La magie virtuelle est un terme qui décrit une nouvelle forme de magie qui se déroule et se vit derrière son écran d’ordinateur, sa télévision ou même son téléphone.</p><p>C’est un terme qui est apparu notamment au début du premier confinement en 2020.</p><p>La magie virtuelle est interactive. Imaginez un show de magie à la télévision ou sur youtube mais où le magicien pourrait voir et s’adresser à chacun des spectateurs et vice et versa.</p>"
        }
      },{
        "@type": "Question",
        "name": "Comment ça marche ?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "<p>En général l’événement se déroule de la manière suivante :</p><p>L’équipe de David vous enverra un lien unique avec l’heure du show, ce qui permettra à chaque personne d’accéder au show sur une plateforme telle que Zoom.</p><p>Chaque événement est unique et David et son équipe sauront répondre à vos questions et vos envies afin de créer un moment qui vous correspond.</p>"
        }
      }, {
        "@type": "Question",
        "name": "A qui convient le show ?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "<p>Evenements corporate et évenements privés.</p><p>Nous conseillons une à deux personnes par ordinateur et un maximum de quatre pour vivre au mieux l’experience.</p><p>C’est un spectacle parlé, plutôt orienté pour les adultes mais convient également aux enfants.</p>"
        }
      }, {
        "@type": "Question",
        "name": "Combien ca coûte ?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "<p>Chaque événement est unique et particulier.</p><p>Les coûts varient essentiellement avec le nombre d’invités et/ou la Mystery Box (voir onglet Mystery box pour plus d’infos).</p><p>Contactez-nous et nous serons heureux d’établir un devis selon votre demande et vos envies.</p>"
        }
      }, {
        "@type": "Question",
        "name": "Combien de temps dure un show ?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "<p>En moyenne un show de Magie virtuelle dure approximativement 40 minutes.</p><p>Pour des formats plus ou moins longs merci de nous contacter.</p><p>David et son équipe seront à votre écoute pour organiser le show qui correspond à vous envies.</p>"
        }
      }]
    }
  }
  
  return (
    <Helmet title={seo.title}>
      <html lang={locale} />
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <meta name="title" content={seo.title} />
      <link rel="canonical" href={seo.url}></link>
      <link rel="preconnect" href="https://fonts.googleapis.com"></link>
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true"></link>
      <link href="https://fonts.googleapis.com/css2?family=Zilla+Slab+Highlight&display=swap" rel="stylesheet"></link>
      <script type="application/ld+json">{JSON.stringify(schemaOrgWebPage)}</script>
      {
        slug !== '/' && (
          <script type="application/ld+json">{JSON.stringify(schemaFAQ[locale])}</script>
        )
      }
    </Helmet>
  )
}

export default SEO;

const query = graphql`
  query SEO {
    site {
      buildTime(formatString: "YYYY-MM-DD")
      siteMetadata {
        siteUrl
        author
      }
    }
  }
`