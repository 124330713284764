import React from "react";
import { Link } from 'gatsby'
import i18n from '../../config/i18n'
import ModalContainer from '../components/ModalContainer'

export default ({ locale, slug, alternates, onSubmit }) => {
  
  return (
    <div className="menu">
      <ul>
        <li>
          <ModalContainer 
            triggerText={i18n[locale].buttons[4]} 
            onSubmit={onSubmit}
            locale={locale}
            button={true}
          />
        </li>
        <li>
          <Link className="mfh" to={locale === 'fr' ? '/evenements/' : '/en/events/'}>
            {i18n[locale].labels[5]}
          </Link>
        </li>
        
        <li style={{ marginBottom: '2rem' }}>
          <Link className="mfh" to={locale === 'fr' ? '/a-propos/' : '/en/about/'}>
            {i18n[locale].labels[4]}
          </Link>
        </li>

        {/* {
          alternates ? (
            <React.Fragment>
              <li><Link to={alternates.fr} className={`${locale === 'fr' ? 'active' : 'default'}`}>FR</Link></li>
              <li><Link to={alternates.en} className={`${locale === 'en' ? 'active' : 'default'}`}>EN</Link></li>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <li><Link to={`/${slug === '/' ? '' : `${slug}/`}`} className={`${locale === 'fr' ? 'active' : 'default'}`}>FR</Link></li>
              <li><Link to={`/en${slug === '/' ? '/' : `/${slug}/>`}`} className={`${locale === 'en' ? 'active' : 'default'}`}>EN</Link></li>
            </React.Fragment>
          )
        } */}
      </ul>
    </div>
  )
};
