import styled from '@emotion/styled'

export const FooterContainer = styled.footer`
  background: #000000;
  font-family: ${props => props.theme.fontFamily};
  width: 100%;
  margin: 0 auto;
  padding: 2rem 2rem 1.5rem;
  font-size: 13px;
  .container {
    max-width: ${props => props.theme.maxWidth};
    margin: 0 auto;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > div {
      p {
        color: #777;
        letter-spacing: 1px;
      }
      a, a:visited, a:focus {
        color: #999;
        text-decoration: none;
        transition: color 0.2s ease-out;
      }
      a:hover {
        color: #777;
      }
      .footer-social {
        margin: 0; 
        padding: 0;
        li {
          font-size: 20px;
          list-style-type: none;
          display: inline-block;
          margin-left: 20px;
          @media (max-width: ${props => props.theme.breakpoints.l}) {
            font-size: 14px;
          }
          @media (max-width: ${props => props.theme.breakpoints.m}) {
            font-size: 12px;
          }
        }
      }
    }
  }
`