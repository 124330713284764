import styled from '@emotion/styled'
import { theme } from '../styles'

export const HeaderWrapper = styled.header`
  width: 100%;
  position: fixed;
  z-index: 1000;
  
  .header-container {
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 25px;
    height: 80px;
    transition: background-color 0.2s ease-out;
    transition: opacity .4s ease-out;

    &.is-sticky {
      box-shadow: 0 5px 16px rgba(0, 0, 0, 0.1);
      animation: slideDown 0.35s ease-out;
      opacity: 1;
    }

    &.black {
      background: #101010;
    }

    &.white {
      background: white;
      border-bottom: 1px solid #ddd;
      .menu {
        a {
          color: #000;
          border-bottom: 2px solid #fff;
          transition: all .3s ease-out;
          &:hover, &.active {
            color: #000;
            border-bottom: 2px solid;
          }
        }
        button {
          color: black;
          &.no-btn {
            color: black;
          }
        }
      }
      .title {
        a, a:hover, a:visited, a:focus, a:active {
          color: #000;
        }
      }
      .social {
        ul {
          li {
            a {
              color: #000;
            }
          }
        }
      }
    }
    > div {
      width: 33.33%;
      > div {
        &.menu {
          a {
            color: #fff;
            display: inline-block;
            text-decoration: none;
            font-size: 15px;
            text-transform: uppercase;
            font-weight: 600;
            margin: 0 .6rem;
            outline: none;
            &:hover {
              color: #fff;
            }
            @media (max-width: ${props => props.theme.breakpoints.l}) {
              font-size: 14px;
            }
            @media (max-width: ${props => props.theme.breakpoints.m}) {
              font-size: 12px;
            }
          }
          button, .buttonLink {
            ${'' /* color: #fff; */}
            color: #000;
            text-transform: uppercase;
            ${'' /* border: 0; */}
            border: 2px solid #8faff4;
            padding: 0 20px;
            cursor: pointer;
            font-weight: 600;
            font-family: ${theme.fontFamily};
            font-size: 16px;
            display: inline-block;
            padding: 5px 10px;
            margin: 0 .8rem;
            border: 1px solid #aaa;
            text-align: center;
            background: #aaa;
            ${'' /* background: transparent; */}
            border-radius: 5px;
            outline: none;
            &.no-btn {
              background: transparent;
              border: none;
              padding: 0;
              letter-spacing: 1px;
              color: white;
            }
            @media (max-width: ${props => props.theme.breakpoints.l}) {
              font-size: 14px;
            }
            @media (max-width: ${props => props.theme.breakpoints.m}) {
              font-size: 12px;
            }
          }
          text-align: center;
          @media (max-width: ${props => props.theme.breakpoints.m}) {
            display: none;
          }
        }
        &.menu-mobile {
          display: none;
          @media (max-width: ${props => props.theme.breakpoints.m}) {
            display: block;
          }
        }
      }
      &.title {
        text-align: center;
        a, a:hover, a:visited, a:focus, a:active {
          font-size: 24px;
          text-transform: uppercase;
          position: relative;
          font-weight: 100;
          font-size: 42px;
          color: #fff;
          text-decoration: none;
          transition: color 0.2s ease-out;
          @media (max-width: ${props => props.theme.breakpoints.l}) {
            font-size: 40px;
          }
          @media (max-width: ${props => props.theme.breakpoints.m}) {
            font-size: 40px;
          }
          @media (max-width: ${props => props.theme.breakpoints.s}) {
            font-size: 20px;
          }
        }
        @media (max-width: ${props => props.theme.breakpoints.s}) {
          width: 60%;
        }
      }
      &.social {
        font-weight: 600;
        text-align: right;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        ul {
          margin: 0 60px;
          padding: 0;
          li {
            list-style-type: none;
            display: inline-block;
            margin: 0 5px;
            a {
              color: #fff;
              text-decoration: none;
              &:hover, &.active {
                text-decoration: underline;
              }
              transition: color 0.2s ease-out;
            }
            @media (max-width: ${props => props.theme.breakpoints.l}) {
              font-size: 14px;
            }
            @media (max-width: ${props => props.theme.breakpoints.m}) {
              font-size: 12px;
            }
          }
          @media (max-width: ${props => props.theme.breakpoints.s}) {
            margin: 0 0 0 30px
          }
          &.locale {
            @media (max-width: ${props => props.theme.breakpoints.s}) {
              display: none
            }
          }
        }
        ul.header-social {
          li {
            font-size: 20px;
            a, a:hover {
              transition: color 0.2s ease-out;
              text-decoration: none;
            }
            @media (max-width: ${props => props.theme.breakpoints.l}) {
              font-size: 16px;
            }
            @media (max-width: ${props => props.theme.breakpoints.m}) {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
`