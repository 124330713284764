import styled from '@emotion/styled'

const MainWrapper = styled.main`
  max-width: 100%;

  .video-responsive {
    @media (max-width: ${props => props.theme.breakpoints.s}) {
      display: none
    }
  }
  padding: 0 40px;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    padding: 0 20px;
  }

  section {
    background: #fefefe;
    &.intro {
      background-size: cover;
      height: calc(100vh - 50px)!important;
      &.auto-height {
        height: auto!important;
      }
      &.desktop {
        background-color: #fff!important;
        @media (max-width: ${props => props.theme.breakpoints.s}) {
          display: none
        }
      }
      &.mobile {
        background-color: #fff!important;
        @media (min-width: ${props => props.theme.breakpoints.s}) {
          display: none;
        }
      }
      h2 {
        position: absolute;
        color: #000;
        font-family: "summerHearts";
        font-size: 86px;
        text-align: center;
        top: 10%;
        left: 18%;
      }
    }

    &.intro-ads {
      &.desktop {
        background-color: #fff!important;
        @media (max-width: ${props => props.theme.breakpoints.s}) {
          display: none;
        }
      }
      &.mobile {
        background-color: #fff!important;
        ${'' /* padding: 5rem 1rem 0 1rem!important; */}
        @media (min-width: ${props => props.theme.breakpoints.s}) {
          display: none;
        }
      }
    }
    &.about {
      padding: 70px 0 175px 0;
      color: #222222;
      @media (max-width: ${props => props.theme.breakpoints.s}) {
        padding: 25px 0 75px 0;
      }
    }
    &.regular {
      padding: 70px 0 0 0;
      color: #222222;
      @media (max-width: ${props => props.theme.breakpoints.s}) {
        padding: 25px 0 75px 0;
      }
    }
    &.faq {
      padding: 5rem 0;
      .faq-title {
        h2 {
          font-size: 36px;
          font-weight: 600;
          @media (max-width: ${props => props.theme.breakpoints.s}) {
            font-size: 20px;
          }
        }
      }
      .row-title-text {
        font-size: 20px;
        font-weight: 500;
        padding: 1rem 0;
        @media (max-width: ${props => props.theme.breakpoints.s}) {
          font-size: 16px;
        }
      }
      .row-content-text {
        p, ul {
          margin: .5rem 0;
          @media (max-width: ${props => props.theme.breakpoints.s}) {
            font-size: 14px;
            padding-left: 1.1rem;
          }
        }
      }
    }
    button, a.cta {
      padding: 15px 20px;
      color: #000;
      border: 2px solid #dc0100;
      border-radius: 5px;
      display: block;
      margin: 4rem auto 0;
      cursor: pointer;
      background: transparent;
      transition: border-color 0.2s ease-out, color 0.2s ease-out;
      font-weight: 600;
      font-size: 16px;
      text-transform: uppercase;
      line-height: 22px;
      outline: none;
      &:hover {
        border-color:#dc0100;
        color:#dc0100;
      }
      &.white {
        background: white;
        border: none;
        display: inline-block;
        margin: 0 auto;
      }
      @media (max-width: ${props => props.theme.breakpoints.s}) {
        max-width: 80%;
        font-size: 12px;
        line-height: 22px;
      }
    }
    .container {
      max-width: ${props => props.theme.maxWidth};
      margin: 0 auto;
      padding-right: 15px;
      padding-left: 15px;
      @media (max-width: ${props => props.theme.breakpoints.s}) {
        padding-right: 15px;
        padding-left: 15px;
      }
      .headline {
        p, H2 {
          font-size: 36px;
          font-weight: 600;
          text-align: center;
          padding: 0 150px;
          line-height: 1.1;
          margin: 20px 0 0;
          @media (max-width: ${props => props.theme.breakpoints.s}) {
            font-size: 20px;
            max-width: 100%;
            padding: 0 0 0;
          }
        }
        H3 {
          font-size: 24px;
          font-weight: 500;
          text-align: center;
          padding: 0 150px;
          line-height: 1.1;
          margin: 0;
          @media (max-width: ${props => props.theme.breakpoints.s}) {
            font-size: 16px;
            max-width: 100%;
            padding: 0 0 0;
          }
        }
        span.credits {
          display: block;
          font-size: 16px;
          ${'' /* font-style: italic; */}
          ${'' /* font-weight: 100; */}
          padding: 30px 0;
          text-align: center;
          etter-spacing: 1px;
        }
        @media (max-width: ${props => props.theme.breakpoints.s}) {
          padding-bottom: 20px;
        }
      }
      .row {
        ${'' /* margin-right: -15px;
        margin-left: -15px; */}
        &:after {
          clear: both;
          display: table;
          content: " ";
        }

        .col-6, .col-12, .col {
          float: left;
          position: relative;
          min-height: 1px;
          padding-right: 15px;
          padding-left: 15px;
          &.np {
            padding-right: 0;
            padding-left: 0;
            &.col-6 {
              &:nth-of-type(even) {
                padding-left: 15px;
                @media (max-width: ${props => props.theme.breakpoints.s}) {
                  padding-left: 0;
                }
              }
              &:nth-of-type(odd) {
                padding-right: 15px;
                @media (max-width: ${props => props.theme.breakpoints.s}) {
                  padding-right: 0;
                }
              }
            }
          }
          &.nf {
            float: none;
            margin: 0 auto;
          }
          p {
            line-height: 25px;
            letter-spacing: 1px;
            text-align: justify;
          }
        }
        .col-6 {
          width: 50%;
          @media (max-width: ${props => props.theme.breakpoints.s}) {
            width: 100%;
          }
        }
        .col {
          max-width: 840px;
        }
        .col-12 {
          width: 100%;
        }
      }
      &.magicFromHome {
        .row {
          .col-12:first-of-type {
            .headline {
              @media (max-width: ${props => props.theme.breakpoints.s}) {
                display: none;
              }
            }
            p:first-of-type {
              @media (max-width: ${props => props.theme.breakpoints.s}) {
                margin-top: 1rem;
              }
            }
          }

          .col-12, .col-6, .col {
            &.pageIntro {
              font-weight: 500; 
              font-size: 1.1rem; 
              margin: 0 auto;
              p {
                &:first-of-type {
                  line-height: 2.1rem; 
                  font-size: 1.37rem;
                  @media (max-width: ${props => props.theme.breakpoints.s}) {
                    font-size: 16px;
                    line-height: 26px;
                  }
                }
                &:last-of-type {
                  line-height: 1.8rem; 
                  @media (max-width: ${props => props.theme.breakpoints.s}) {
                    padding-top: 1rem;
                    font-size: 14px;
                    line-height: 24px;
                    font-weight: 400;
                  }
                }
              }
            }
            h2 {
              font-size: 28px;
              letter-spacing: 0;
              font-weight: 600;
              padding: 0 0;
              line-height: 1.1;
              margin: 25px 0 30px;
              @media (max-width: ${props => props.theme.breakpoints.s}) {
                font-size: 19px;
                line-height: 28px;
              }
            }
            p {
              @media (max-width: ${props => props.theme.breakpoints.s}) {
                font-size: 14px;
                line-height: 22px;
              }
            }
          }
        }
        .headline {
          p {
            font-size: 36px;
            font-weight: 600;
            text-align: center;
            padding: 0 150px;
            line-height: 1.1;
            margin: 20px 0 0;
            padding: 50px 100px 0;
            max-width: 1080px;
            @media (max-width: ${props => props.theme.breakpoints.s}) {
              font-size: 20px;
              max-width: 100%;
              padding: 0 0 0;
            }
          }
        }
        .m-p {
          max-width: 840px;
          text-align: center; 
          margin: 20px auto; 
          line-height: 25px;
        }
        .m-p-b {
          font-weight: 500;
          line-height: 22px;
          max-width: 840px;
          text-align: justify; 
          margin: 30px auto 50px;
        }
      }
    }
  }
`



export default MainWrapper
